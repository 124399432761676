import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navigation from './navigation';
import '../styles/header-footer.css';

const Header = ({ siteTitle }) => (
  <header
    className="header"
  >
    <div
      className="header__title"
    >
      <h1 className="header__heading1 header__item" style={{ margin: 0 }}>
        <Link
          className="header__heading1 header__link"
          to="/"
        >
          {/* {siteTitle} */}

          Locationbüro Sattel&middot;Fessel&middot;Fouquet
        </Link>
      </h1>
    </div>
    <div className="header__nav">
      <Navigation />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
