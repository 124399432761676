import { Link } from 'gatsby';
// import { PropTypes } from 'prop-types';
import React from 'react';

const Navigation = () => (
    <nav className="main-navigation">
        <ul className="menu">
            <li className="menu__menu-item header__item"><Link className="header__link" to="/motive" activeClassName="active"
            >Motive</Link></li>
            <li className="menu__menu-item header__item"><Link className="header__link" to="/referenzen/" activeClassName="active"
            >Referenzen</Link></li>
            <li className="menu__menu-item header__item"><Link className="header__link" to="/kontakt/" activeClassName="active"
            >Kontakt</Link></li>

        </ul>
    </nav>
);

export default Navigation;