import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import Navigation from "./navigation"
// import "../styles/footer.css"

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <div className="footer__nav">
      <nav className="main-navigation">
        <ul className="menu">
          <li className="menu__menu-item footer__item">
            <Link
              className="footer__link"
              to="/datenschutz"
              activeClassName="active"
            >
              Datenschutz
            </Link>
          </li>
          <li className="menu__menu-item footer__item">
            <Link
              className="footer__link"
              to="/impressum/"
              activeClassName="active"
            >
              Impressum
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
